<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="column container">
      <q-card square class="bg-white">
        <q-card-section class="bg-primary">
          {{ $t(`bikehire.brompton.show["${show}"]`) }}
        </q-card-section>
        <q-card-section v-if="bikehireStatus.brompton && canSetDetails.includes(bikehireStatus.brompton.status)">
          <q-btn class="bikehire-btn" color="primary" :label="$t('bikehire.set_details')" @click="show = 'Log In'" />
          <q-btn class="bikehire-btn" color="primary" outline :label="$t('sign_up')" @click="show = 'Sign Up'" />
        </q-card-section>
        <q-card-section v-if="show === 'Log In'">
          <login-form :user="authedUser" @complete="completeLogIn" />
        </q-card-section>
        <q-card-section v-if="show === 'Sign Up'">
          <signup-form :user="authedUser" @complete="completeSignUp" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-banner v-if="message" rounded :class="`bg-${statusColor}`" style="margin-top:0">
            {{ message }}
            <strong v-if="registrationUrl">
              <a :href="registrationUrl"> here</a>.
            </strong>
          </q-banner>

          <div v-if="bikehireStatus.brompton" class="status-details">
            <strong>{{ $t('member_status') }}: </strong>
            <div :class="`status-icon`">
              {{ bikehireStatus.brompton.status }}
            </div>
            <strong>{{ $t('phone_number') }}:</strong>
            {{ authedUser.meta.bikehire.brompton.phone_number }}
          </div>
          <q-banner
            rounded
            class="bg-primary"
          >
            <div class="status-card">
              {{ $t('for_queries_contact_logo') }}
              <img :src="logo" style="width:50%; margin:10px 0">
              {{ supportNumber }}
            </div>
          </q-banner>
        </q-card-section>
        <q-separator />
      </q-card>
      <div v-if="show === 'Brompton Membership'" class="column text-center text-black cta">
        {{ $t('bikehire.want_to_search') }}
        <m-cta-button :label="$t('bikehire.search')" @ctaClick="searchRequest" />
      </div>
    </div>
  </q-page>
</template>

<script>
import 'styles/ondemand.styl'
import { mapGetters } from 'vuex'
import signupForm from './signup.vue'
import loginForm from './login.vue'
import logo from 'assets/bike-providers/brompton_logo.svg'
import { MCtaButton } from 'components'

export default {
  name: 'BromptonLogIn',
  components: {
    signupForm,
    loginForm,
    MCtaButton
  },
  data () {
    return {
      show: 'Brompton Membership',
      message: null,
      supportNumber: this.$t('bikehire.brompton.support_number'),
      statusColor: 'primary',
      logo,
      canSetDetails: ['Not registered', 'Missing phone number']
    }
  },
  computed: {
    ...mapGetters({
      authedUser: 'user',
      bikehireStatus: 'bikehire/status'
    }),
    registrationUrl () {
      return this.bikehireStatus.brompton.registration_return_url
    }
  },
  async beforeMount () {
    if (!this.bikehireStatus.brompton) await this.$store.dispatch('bikehire/setBikehireStatus', this.authedUser.username)
    if (this.bikehireStatus.brompton.status === 'Inactive member') {
      this.show = 'Brompton Membership'
      this.message = this.$t('bikehire.brompton.status["Inactive member"]')
      this.statusColor = 'warning'
    } else if (this.bikehireStatus.brompton.status === 'Membership pending') {
      this.$store.dispatch('bikehire/setRegistrationUrl', this.authedUser.meta.bikehire.brompton.registration_return_url)
      this.show = 'Brompton Membership'
      this.message = this.$t('bikehire.please_complete_registration')
      this.statusColor = 'warning'
    } else if (this.$route.params.show) {
      this.show = this.$route.params.show
    }

    if (!this.authedUser.meta.bikehire) {
      this.authedUser.meta = {
        ...this.authedUser.meta,
        bikehire: {
          brompton: {
            phone_number: this.$t('bikehire.no_phone_number')
          }
        }
      }
    }
  },
  methods: {
    completeSignUp (url) {
      this.show = 'Brompton Membership'
      this.$store.dispatch('bikehire/setRegistrationUrl', url)
      this.bikehireStatus.brompton.status = 'Membership pending'
      this.message = this.$t('bikehire.please_complete_registration')
      this.statusColor = 'warning'
    },
    completeLogIn () {
      this.show = 'Brompton Membership'
      this.message = this.$t('logged_in')
      this.statusColor = 'primary'
    },
    async searchRequest () {
      await this.$store.dispatch('bikehire/setSearchType', 'search')
      this.$router.push({ name: 'ondemand-bikehire' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-banner
  border none
</style>
